import cx from 'classnames';

import React from 'react';

import { CategoriesFilterPanel } from '../../components/blog/categories-filter-panel';
import { Hero } from '../../components/blog/hero';
import { PostSidebar } from '../../components/blog/post-sidebar';
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs';
import { Container } from '../../components/container';
import SEO from '../../components/seo';
import img1 from './Jak_zhubnout_10_kg.png';
import styles from './post-grid.module.css';

const JakZhubnoutDesetKg = () => {
    return (
      <div className={styles.wrapper}>
        <SEO
          title={"Jak zhubnout 10 kilo a udržet si váhu? | NutritionPro"}
          description={"Přinášíme vám unikátní tipy, jak zhubnout 10 kg a váhu si udržet. Drastická dieta rozhodně není správným řešením. Zkuste například krabičkovou dietu."}
        />
        <Container>
          <Breadcrumbs style={{ margin: "24px 0" }}>
            <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
            <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
            <BreadcrumbsItem link="/blog/zdrave-udrzitelne-hubnuti">
            Jak zhubnout 10 kilo a udržet si váhu?
            </BreadcrumbsItem>
          </Breadcrumbs>
        </Container>
        <Container>
          <div className={styles.postGrid}>
            <div>
              <Hero
                title="Jak zhubnout 10 kilo a udržet si váhu?"
                date="16.05.22"
              />
              <div>
                <p className={styles.postText}>
                Velmi často se stává, že lidé si během hubnutí dají až nereálné cíle. Jedním z dobrých milníků při hubnutí je 5 nebo 10 kg. Jakmile zhubnete prvních 5 kilo, poznáte to nejen na váze, ale také na tom, že se budete cítit lépe. Když tělesná váha klesne o 10 kg, <b>jde to už výrazně vidět také na vaší postavě</b>. Není se tak čemu divit, že se lidé často ptají, jak zhubnout 10 kilo. Je to významný milník k vysněné postavě.
                </p>
                <img
                  src={img1}
                  className={styles.postImg}
                  style={{ marginBottom: "40px" }}
                  alt="img1"
                />                
                <h5 className={styles.postTitle}>
                Pozor na zaručeně nejúčinnější diety
                </h5>
                <p className={styles.postText}>
                Lidé v souvislosti s hubnutím často hledají <b>nejúčinnější dietu</b>, aby shodili první kila rychle. To by ovšem nemělo být vaším cílem, protože kila se potom mohou zase rychle vrátit. <b>Čím je dieta drastičtější a omezuje příjem živin a kalorií, tím více hrozí takzvaný jojo efekt</b>. Důležité pro vás je naopak hubnout udržitelně a zdravě. Jedině tak zhubnete bez rizika.
                </p>
                <p className={styles.postText}>
                    <em>
                    <b>TIP</b>: Přečtete si náš článek, <a href='https://nutritionpro.cz/blog/zdrave-udrzitelne-hubnuti/'>jak na zdravé a udržitelné hubnutí</a>. Dozvíte se mimo jiné ideální poměr živin, když chcete zhubnout. 
                    </em>
                </p>
                <h5 className={styles.postTitle}>
                Tipy, jak zhubnout 10 kg
                </h5>
                <ol className={cx(styles.postList, styles.postListNumber)}>
                <li>
                  <strong>Neřešte, za jak dlouho zhubnete</strong>
                  <p>
                  Rozhodně není ideální si dát termín, do kdy musíte zhubnout. Potom budete zbytečně ve stresu a možná sáhnete i po drastické dietě. Měli byste hubnout v poklidném tempu, mít radost z každého dne, kdy nejíte zbytečně kalorická jídla, kdy se vyhnete rychlému občerstvení, kdy hubnete. Hubnutí je pomalý proces, ale stojí za to vydržet. Mějte proto trpělivost. 
                  </p>
                </li>
                <li>
                  <strong>Sledujte čísla a data</strong>
                  <p>
                  Ideální radou, jak zhubnout 10 kg, je sledovat čísla a data. Sledujte, jaký máte kalorický deficit – bez něj nezhubnete. Je proto důležité mít menší příjem kalorií než výdej. Kalorický deficit je sama o sobě ta nejúčinnější dieta. Doporučujeme váš kalorický deficit držet zhruba na 10 až 20 %, podle toho, jak se budete cítit. Vyplatí se také sledovat rozložení živin ve stravě během dne.
                  </p>
                </li>
                <li>
                  <strong>Dostaňte se do formy</strong>
                  <p>
                  Zhubnout bez sportu je sice možné, ale trvá to o mnoho déle. Není nad to, začít sportovat. Pokud se dostanete do formy, sport vám půjde o to snáz a nebudete se například při běhu tolik trápit. Pravidelným sportováním můžete velmi rychle zhubnout 10 kg, a to už do 3 až 4 měsíců. Navíc sport má mnoho dalších výhod nejen pro naše tělo, ale také mysl.
                  </p>
                  <p style={{ marginTop: "24px" }}>
                      <em>
                      Podívejte se, <a href='https://nutritionpro.cz/blog/dostante-se-do-formy/'>jak se dostat do formy díky zdravému stravování</a>. Unikátní tipy, se kterými, ještě více podpoříte své hubnutí. 
                      </em>
                  </p>
                </li>
                <li>
                  <strong>Dopřejte si vyvážené jídlo</strong>
                  <p>
                  Jak zhubnout 10 kg? Jedině s vyváženým jídlem. Pokud budete jíst vyvážené jídlo a přijímat všechny důležité živiny, vyhnete se jojo efektu. Jenže denně si vařit je opravdu nejen časově náročné. Vhodnou volbou v tomto případě může být takzvaná krabičková dieta. Vyzkoušejte speciální <a href='https://nutritionpro.cz/products/product-1/'></a>hubnoucí program, ve kterém vám připraví vyvážené a pestré jídlo na snídani, oběd i večeři. Ideální volba pro časově zaneprázdněné lidi, kteří chtějí zhubnout.
                  </p>
                </li>
                <li>
                  <strong>Odměňte se</strong>
                  <p>
                  Slibte si odměnu, to je další skvělý trik, jak ještě snadněji zhubnout 10 kg. Samozřejmě by odměnou neměl být například sladký dort (i když čas od času to také není nutně špatně). Skvělou odměnou za zhubnutí kil může být například nákup nového oblečení – stejně ho budete potřebovat, protože po zhubnutí 10 kilo vám bude stávající oblečení velké.
                  </p>
                </li>
              </ol>
              </div>
            </div>
            <PostSidebar />
          </div>
        </Container>
      </div>
    )
  }
  
  export default JakZhubnoutDesetKg